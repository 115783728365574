header .dc-icon{
	height: 50px;
	margin-top: 10px;
	width: 50px;
}

#home .icon {
	display: block;
	margin: 0 auto;
	width: 800px;
	padding-left: 118px;
}

#visit_box .icon,
#contact_box .icon{
	height: 28px;
	width: 28px;
}

footer .icon {
	display: inline-block;
	height: 50px;
	width: 50px;
}

.decohr.icon{
	display: block;
	margin: 0 auto;
}

.decohr.icon.mirrored {
	transform: rotate(180deg);
}


#works .icon{
	width: 260px;
	height: 260px;
	display: inline-block;
	vertical-align: top;
}