textarea {
  background-color: $background;
  height: 100px;
  padding: 10px 4px;
  width: 330px;
}

input[type="text"],
input[type="email"],
input[type="tel"]{
  background-color: $background;
  font-size: 20px;
  padding: 10px 4px;
  width: 330px;
}

form button {
  background-color: $secondary;
  background-position: 83% center;
  background-repeat: no-repeat;
  border: 0;
  border-radius: 3px;
  color: $background;
  cursor: pointer;
  font-size: 16px;
  height: 50px;
  left: 30px;
  padding: 5px 8px 0 15px;
  position: relative;
  text-align: left;
  width: 120px;

  span {
    display: inline-block;
    font-size: 18px;
    line-height: 20px;
    vertical-align: top;
  }

  .button-icon {
    margin: -5px 5px;
    vertical-align: top;
  }

}
