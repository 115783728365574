header {
  background-color: $primary;
  height: 70px;
  opacity: .98;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;

  .dc-logo {
    margin-top: 10px;
  }

  nav {
    display: inline-block;
    left: 640px;
    position: relative;
    vertical-align: top;
    line-height: 50px;
    a {
      border-left: 1px solid rgba(0, 0, 0, .1);
      color: $background-secondary;
      display: inline-block;
      height: 50px;
      &:hover {
        background-color: $background;
        color: $primary;
      }
    }
  }
}


nav>a:after{
    content: " ";
    border: 1px solid #3c4e5a;   
    height: 1px;
    display: block;
    width: 0;
    border-radius: 2px;
    transition: width .2s ease-in-out;
    margin: .25em auto 0;
}

header nav>a:after{
    position: relative;
    top: -20px;
}

nav>a:hover:after {
    width: 100%;
    border: 1px solid #ad4242;
}