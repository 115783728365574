body {
  background-color: $background;
  color: $basefont;
  font-family: 'Lato light', Arial, Helvetica, sans-serif;
  font-size: 1em;
  line-height: 1;
}

.wrap {
  margin: 0 auto;
  max-width: 960px;
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Mus 700', sans-serif;
}

p {
  font-size: 1.1em;
  line-height: 1.5em;
}

a {
  backface-visibility: hidden;
  text-decoration: underline;
  transition: .35s;
}

a:hover {
  color: $secondary-hover;
  text-decoration: none;
}

label {
  color: $background;
  font-size: 1em;
  line-height: 2.2em;
}

nav a {
  color: $background;
  display: inline-block;
  font-size: .8em;
  padding: 10px 16px;
  text-decoration: none;
  text-transform: uppercase;
}

section#home {
  margin-top: 176px;
}

section#home hr {
  border: 4px solid $secondary;
  height: 3px;
  width: 130px;
}

section#home h2 {
  color: $primary;
  font-size: 2.4em;
  font-weight: 400;
  line-height: 1.8em;
  text-align: center;
}

section#about {
  margin: 0 auto;
  padding: 65px 0 95px;
  width: 715px;
}

section#about h3 {
  font-family: 'Lato light', sans-serif;
  font-size: 1.6em;
  font-weight: 300;
  line-height: 1.9em;
}

section#contact { 
  border-top: 1px solid $background-secondary;
  padding: 136px 0;
  p{
    line-height: 1.6em;
    font-size: 1.3em;
    margin: 20px auto 40px auto;
    width: 950px;
    text-align: center;
  }
}

section#contact > ul {
  width: 545px;
  margin: 0 auto;
}

section#contact > ul li {
  text-align: center;
  display: inline;
}

section#contact > ul li:first-child{
  margin-right: 55px;
}

section#contact > ul li a {
  background-color: $primary;
  border-radius: 3px;
  color: $background;
  display: inline-block;
  font-size: 16px;
  margin-left: 2.5px;
  padding: 24px 0;
  text-decoration: none;
  text-transform: uppercase;
  width: 240px;
}

section#contact > ul li a.button:hover {
  background-color: $background-secondary;
  color: $primary;
}

section#contact > ul li a.button:active{ 
	background-color: $primary;
  color: $background;
} 

.pop-over {
  background-color: $primary;
  border-radius: 3px;
  height: 0;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  top: 34px;
  transition: height 1s ease-in-out;
  width: 540px;
}

.pop-over:after {
  border: solid transparent;
  border-bottom-color: $primary;
  border-width: 30px;
  bottom: 100%;
  content: '';
  height: 0;
  margin-left: -30px;
  pointer-events: none;
  position: absolute;
  width: 0;
}

.pop-over h3 {
  height: 44px;
  background-repeat: no-repeat;
  background-position: 95% center;
  border-bottom: 1px solid $background;
}

.pop-over input[type="text"],
.pop-over input[type="email"],
.pop-over input[type="tel"],
.pop-over textarea{
  width: 375px;
}

.pop-over form button{
  left: 60px;
}

.pop-over form button span{
  color: #d9e1e8;
}

.content_inner {
  height: inherit;
  overflow: hidden;
}



#contact_box{
  button .icon{
    margin: -6px 0 0 6px;
  }

  &:after {
    left: 17%;
  }

  h3{
    .icon{
      float: right;
      position: relative;
      right: 26px;
      top: 8px;
    }
  }
}

#visit_box {
  .icon{
    float: right;
    margin: 8px 28px 0 0;
  }

  &:after { 
   right: 17%;
  } 
  
  address {
    color: $background;
    display: inline-block;
    font-size: 1.4em;
    line-height: 1.6em;
    margin: 12px 38px;
    padding-top: 12px;
    vertical-align: top;
  }
}
section#work {
  background-color: $background-secondary;
  border-bottom: 1px solid $primary;
  border-top: 1px solid $primary;
  padding: 44px 0;
  box-shadow: inset 0 3px 3px -3px $basefont, inset 0 -3px 3px -3px $basefont;
}

section#work ul {
  margin: 60px inherit;
}

section#work ul li {
  margin-bottom: 122px;
}

section#work p {
  display: inline-block;
  font-size: 1.5em;
  font-weight: 300;
  padding-left: 65px;
  text-indent: 0;
  vertical-align: middle;
  width: 610px;
}

section#work hr{
  margin-bottom: 26px;
}

section#work h2, 
section#partners h2 {
  color: $primary;
  font-size: 38px;
  margin: 0 0 122px 0;
  text-align: center;
}

section#work h3 {
  color: $secondary;
  font-size: 2.3em;
  font-weight: bold;
  margin-bottom: 16px;
  text-align: center;
}

.contact-form label {
  border-bottom: 1px solid $background-secondary;
  display: block;
  padding: 12px 24px; 
  position: relative;
}

.contact-form label:last-child {
  border-bottom: 0;
}

#physical_address {
  border-top: 1px solid $background;
}

dl#other_contact {
  display: inline-block;
  padding: 12px 24px;
  width: 280px;
}

dl#other_contact a {
  color: $secondary;
  font-size: 1.4em;
  text-shadow: 1px 1px rgba(0, 0, 0, .4);
}

dl#other_contact dd {
  margin: 8px 0 12px 8px;
}

#contact_box label span {
  color: $background;
  display: inline-block;
  text-transform: uppercase;
  width: 100px;
}

#map-canvas {
  height: 320px;
  margin: 12px auto;
  width: 450px;
}

dl#other_contact dt {
  color: $background;
}

.deco-hr {
  display: block;
  margin: 0 auto;
}  

hr.dc_custom.mirrored {
  transform: rotate(180deg);
}