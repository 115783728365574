@font-face {
  font-family: 'Mus 700';
  src: url('../fonts/Mus700-Regular.eot'); // IE9 Compat Modes
  src: url('../fonts/Mus700-Regular.eot?#iefix') format('embedded-opentype'), //IE6-IE8
       url('../fonts/Mus700-Regular.woff') format('woff'), //Modern Browsers
       url('../fonts/Mus700-Regular.ttf')  format('truetype'), //Safari, Android, iOS
       url('../fonts/Mus700-Regular.svg#bbb8d732448291d0f99910627ede3ed9') format('svg'); //Legacy iOS
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Lato light';
  src: url('../fonts/Lato-Light.eot'); // IE9 Compat Modes
  src: url('../fonts/Lato-Light.eot?#iefix') format('embedded-opentype'), // IE6-IE8
       url('../fonts/Lato-Light.woff') format('woff'), // Modern Browsers
       url('../fonts/Lato-Light.ttf')  format('truetype'), // Safari, Android, iOS
       url('../fonts/Lato-Light.svg#bbb8d732448291d0f99910627ede3ed9') format('svg'); // Legacy iOS
  font-style: normal;
  font-weight: 400;
}
