footer {
  background-color: $primary;
  margin: 0;
  padding: 40px 0;

  .dc-logo {
    margin-top: 4px;
  }

  nav {
    display: inline-block;
    left: 66px;
    position: relative;
    top: -19px;

    a {
      border-radius: 3px;

      &:hover {
        background-color: $background-secondary;
        color: $primary;
      }
    }
  }

  .dc-copy{
    display: inline-block;
    width: 300px;
  }

  .copyright {
    color: $background-secondary;
    display: inline-block;
    font-size: 12px;
    line-height: 16px;
    margin-top: 14px;
    text-align: center;
    vertical-align: top;
    width: 230px;
  }

  ul#social {
    float: right;
    margin-top: 4px;
    li {
      display: inline-block;

      a {
        display: inline-block;
        height: 50px;
        margin-left: 4px;
        margin-right: 0;
        width: 51px;
      }

      &:first-child {
        margin-left: 0;
      }
    }
  }
}
