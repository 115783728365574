@media only screen 
and (min-device-width : 320px) 
and (max-device-width : 568px) {

	header{
		height: 48px;
		position: relative;
	}
	header .dc-icon{
		display: none;
	}
	.wrap{width: 100%}
	header nav{ 
		vertical-align: middle; 
		left: 0px;
		width: 100%;
	}

	header nav a{ 
		font-size: 18px;  
		line-height: 48px; 
		padding: 0;
		width: 30%;
		text-align: center;
	}
   
   .wrap{  width: 100%; margin: 0;}
   
   #works li h3{
   	width: 100%;
   }
   #works li .icon{
   	display: block;
   	margin: 0 auto;
   }
 
	section#home {
		margin-top: 40px;
	}
	
	section#contact > ul{
		width: 100%;
	}

	#home .icon{
		width: 95%;
		margin: 0 auto;
	}
	section#work p,
	section#about,
	section#contact p{
		width: 92%;
		margin: 0 auto;
		padding-left: 0;
		display: block;
	}
	section#work p{
		margin-top: 18px;
	}

	#home .icon{
		padding-left: 0;
	}

	section#contact ul#more_infos{
		margin-top: 80px;
		width: 92%;
	}
	section#contact > ul li a{
		width: 45%;
	} 
	section#contact > ul li:first-child{
		margin-right: 7%;
	}

	footer nav,
	footer ul#social{
		display: block;
		float: none;
		width: 300px;
		margin: 0 auto;
		left: 0;
		top: 0;
		text-align: center;
	}
	footer .dc-copy{
		margin: 0 auto;
		display: block;
	}
	
	footer nav{
		margin-top: 12px;
		margin-bottom: 12px;
	}

	footer ul#social li{
		width: 30%;
	}
}